.sup-search-form {
  display: block;
  width: 100%;
  position: relative;
}

.sup-search-form__textfield {
  color: inherit;
  display: inline-block;
  width: calc(100% - 60px);
  border-bottom-left-radius: 28px;
  border-top-left-radius: 28px;
  padding: 16px;
  -webkit-appearance: none;
  border: 0;
  vertical-align: top;
  margin: 0;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.25rem;
}

.sup-search-form__button {
  right: .5rem;
  color: inherit;
  height: 100%;
  width: 60px;
  display: inline-block;
  border-bottom-right-radius: 29px;
  border-top-right-radius: 29px;
  border: 0;
  vertical-align: top;
  background-color: transparent;
  padding: 0;
  position: absolute;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sup-search-form__button svg {
  width: 70%;
  height: 70%;
  color: inherit;
}
