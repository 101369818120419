@import url("../imports/vars.css");
.sup-core-toggle {
  outline: none;
}

.sup-core-toggle span {
  margin: 0 10px 0;
  background: var(--sup-gray-bergen);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.sup-core-toggle svg {
  width: 15px;
  height: 8px;
}

.sup-core-toggle[aria-expanded="false"] svg {
  transform: rotate(180deg);
}

.sup-core-toggle[aria-expanded="true"] svg {
  transform: rotate(0deg);
  margin-bottom: 1px;
}

@media (max-width: 40rem) {
  .sup-core-toggle {
    width: 100%;
  }
  .sup-core-toggle span {
    background: transparent;
  }

  .sup-core-toggle[aria-expanded="false"] svg {
    position: absolute;
    right: 15px;
    margin-top: 15px;
  }

  .sup-core-toggle[aria-expanded="true"] svg {
    position: absolute;
    right: 15px;
    margin-top: 15px;
  }
}
