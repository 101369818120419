/* Superblue
------------------------------------------------------------------------------sup-color- */
.sup-color-supershine { background-color: var(--sup-color-supershine); color: var(--sup-color-black); }
.sup-color-superlight { background-color: var(--sup-color-superlight); color: var(--sup-color-black); }
.sup-color-superpale  { background-color: var(--sup-color-superpale); color: var(--sup-color-black); }
.sup-color-superchalk { background-color: var(--sup-color-superchalk); color: var(--sup-color-black); }
.sup-color-superblue  { background-color: var(--sup-color-superblue); color: var(--sup-color-black); }
.sup-color-supershade { background-color: var(--sup-color-supershade); color: var(--sup-color-white); }
.sup-color-superdusk  { background-color: var(--sup-color-superdusk); color: var(--sup-color-white); }
.sup-color-superdark  { background-color: var(--sup-color-superdark); color: var(--sup-color-white); }
.sup-color-supernight { background-color: var(--sup-color-supernight); color: var(--sup-color-white); }
/* Grayshades
------------------------------------------------------------------------------sup-color- */
.sup-color-bergen     { background-color: var(--sup-gray-bergen); color: var(--sup-color-black); }
.sup-color-london     { background-color: var(--sup-gray-london); color: var(--sup-color-black); }
.sup-color-detroit    { background-color: var(--sup-gray-detroit); color: var(--sup-color-white); }
.sup-color-moss       { background-color: var(--sup-gray-moss); color: var(--sup-color-white); }

/* Other colors
------------------------------------------------------------------------------sup-color- */
.sup-color-coral       { background-color: var(--sup-color-coral); color: var(--sup-color-black); }
.sup-color-sunshine    { background-color: var(--sup-color-sunshine); color: var(--sup-color-black); }
.sup-color-marine      { background-color: var(--var-color-marine); color: var(--sup-color-white); }
.sup-color-black       { background-color: var(--sup-color-black); color: var(--sup-color-white); }
.sup-color-white       { background-color: var(--sup-color-white); color: var(--sup-color-black); }

/* Hover colors
------------------------------------------------------------------------------ */
.sup-hover-supershine:hover,  .sup-hover-supershine:focus   { background-color: var(--sup-color-supershine); color: var(--sup-color-black); }
.sup-hover-superlight:hover,  .sup-hover-superlight:focus   { background-color: var(--sup-color-superlight); color: var(--sup-color-black); }
.sup-hover-superpale:hover,   .sup-hover-superpale:focus    { background-color: var(--sup-color-superpale); color: var(--sup-color-black); }
.sup-hover-superchalk:hover,  .sup-hover-superchalk:focus   { background-color: var(--sup-color-superchalk); color: var(--sup-color-black); }
.sup-hover-superblue:hover,   .sup-hover-superblue:focus    { background-color: var(--sup-color-superblue); color: var(--sup-color-white); }
.sup-hover-supershade:hover,  .sup-hover-supershade:focus   { background-color: var(--sup-color-supershade); color: var(--sup-color-white); }
.sup-hover-superdusk:hover,   .sup-hover-superdusk:focus    { background-color: var(--sup-color-superdusk); color: var(--sup-color-white); }
.sup-hover-superdark:hover,   .sup-hover-superdark:focus    { background-color: var(--sup-color-superdark); color: var(--sup-color-white); }
.sup-hover-supernight:hover,  .sup-hover-supernight:focus   { background-color: var(--sup-color-supernight); color: var(--sup-color-white); }
.sup-hover-bergen:hover,      .sup-hover-bergen:focus       { background-color: var(--sup-gray-bergen); color: var(--sup-color-white); }
.sup-hover-london:hover,      .sup-hover-london:focus       { background-color: var(--sup-gray-london); color: var(--sup-color-white); }
.sup-hover-detroit:hover,     .sup-hover-detroit:focus      { background-color: var(--sup-gray-detroit); color: var(--sup-color-white); }
.sup-hover-moss:hover,        .sup-hover-moss:focus         { background-color: var(--sup-gray-moss); color: var(--sup-color-white); }
.sup-hover-coral:hover,       .sup-hover-coral:focus        { background-color: var(--sup-color-coral); color: var(--sup-color-white); }
.sup-hover-sunshine:hover,    .sup-hover-sunshine:focus     { background-color: var(--sup-color-sunshine); color: var(--sup-color-white); }
.sup-hover-marine:hover,      .sup-hover-marine:focus       { background-color: var(--var-color-marine); color: var(--sup-color-white); }
.sup-hover-black:hover,       .sup-hover-black:focus        { background-color: var(--sup-color-black); color: var(--sup-color-white); }
.sup-hover-white:hover,       .sup-hover-white:focus        { background-color: var(--sup-color-white); color: var(--sup-color-black); }

/* Text colors
------------------------------------------------------------------------------ */
.sup-text-supershine    { color: var(--sup-color-supershine); }
.sup-text-superlight    { color: var(--sup-color-superlight); }
.sup-text-superpale     { color: var(--sup-color-superpale); }
.sup-text-superchalk    { color: var(--sup-color-superchalk); }
.sup-text-superblue     { color: var(--sup-color-superblue); }
.sup-text-supershade    { color: var(--sup-color-supershade); }
.sup-text-superdusk     { color: var(--sup-color-superdusk); }
.sup-text-superdark     { color: var(--sup-color-superdark); }
.sup-text-supernight    { color: var(--sup-color-supernight); }
.sup-text-bergen        { color: var(--sup-gray-bergen); }
.sup-text-london        { color: var(--sup-gray-london); }
.sup-text-detroit       { color: var(--sup-gray-detroit); }
.sup-text-moss          { color: var(--sup-gray-moss); }
.sup-text-coral         { color: var(--sup-color-coral); }
.sup-text-sunshine      { color: var(--sup-color-sunshine); }
.sup-text-marine        { color: var(--var-color-marine); }
.sup-text-black         { color: var(--sup-color-black); }
.sup-text-white         { color: var(--sup-color-white); }
