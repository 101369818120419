@import url("imports/vars.css");

.sup-figure {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
}
.sup-figure p { margin: 0; }

.sup-figure__image {
  display: block;
  width: 100%;
}

.sup-figure__caption {
  padding: var(--sup-pad-medium);
  background-color: var(--sup-gray-london);
  color: var(--sup-color-black);
}

.sup-figure__overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--sup-pad-medium);
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--sup-color-black);
  text-align: center;
  font-style: normal;
  font-weight: bold;
}

.sup-figure__watermark {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 50%;
}

.sup-figure--16x9::before { /* Use before to correcly size even when .sup-figure has width */
  content: '';
  display: block;
  padding-top: 56.25%;
}

.sup-figure--4x3::before { /* Use before to correcly size even when .sup-figure has width */
  content: '';
  display: block;
  padding-top: 56.25%;
}

.sup-figure__aspect {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
