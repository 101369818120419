/* Container
------------------------------------------------------------------------------ */
.sup-container {
  max-width: 1180px;
  margin: 0 auto;
}

/* Font weights
------------------------------------------------------------------------------ */
.sup-fwb { font-weight: bold; }
.sup-fwm { font-weight: 500; }

/* Display
------------------------------------------------------------------------------ */
.sup-dn { display: none; }
.sup-db { display: block; }
.sup-df { display: flex; }
.sup-dib { display: inline-block; }

/* Positioning
------------------------------------------------------------------------------ */
.sup-posr { position: relative; }
.sup-posa { position: absolute; }

/* Flex
------------------------------------------------------------------------------ */
.sup-aic { align-items: center; } /* vertical align center */
.sup-flex-right { justify-content: flex-end; } /* align flex content right */
.sup-flex-left { justify-content: flex-start; } /* align flex content right */
.sup-flex-center { justify-content: center; }

/* Border radius
------------------------------------------------------------------------------ */
.sup-brs { border-radius: var(--sup-pad-small); }
.sup-brm { border-radius: var(--sup-pad-medium); }
.sup-brl { border-radius: var(--sup-pad-large); }
.sup-brxl { border-radius: var(--sup-pad-large); }

/* Lists
------------------------------------------------------------------------------ */
.sup-list-nostyle { list-style-type: none; }

/* Text
------------------------------------------------------------------------------ */
.sup-text-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sup-tdu { text-decoration: underline; }
.sup-tdn { text-decoration: none; }
.sup-tdu--hover:hover { text-decoration: underline; }
.sup-tdn--hover:hover { text-decoration: none; }

/* Alignment
------------------------------------------------------------------------------ */
.sup-tac, .sup-text-center { text-align: center; }
.sup-tar, .sup-text-right { text-align: right; }
.sup-tal, .sup-text-left { text-align: left; }

/* Shapes
------------------------------------------------------------------------------ */
.sup-o { border-radius: 100%; } /* make whatever circled */


/* Padding
------------------------------------------------------------------------------ */

/* Padding Small */
.sup-pas { padding: var(--sup-pad-small); }
.sup-prs { padding-right: var(--sup-pad-small); }
.sup-pls { padding-left: var(--sup-pad-small); }
.sup-pts { padding-top: var(--sup-pad-small); }
.sup-pbs { padding-bottom: var(--sup-pad-small); }
.sup-phs { padding-left: var(--sup-pad-small); padding-right: var(--sup-pad-small); }
.sup-pvs { padding-top: var(--sup-pad-small); padding-bottom: var(--sup-pad-small); }

/* Padding Medium */
.sup-pam { padding: var(--sup-pad-medium); }
.sup-prm { padding-right: var(--sup-pad-medium); }
.sup-plm { padding-left: var(--sup-pad-medium); }
.sup-ptm { padding-top: var(--sup-pad-medium); }
.sup-pbm { padding-bottom: var(--sup-pad-medium); }
.sup-phm { padding-left: var(--sup-pad-medium); padding-right: var(--sup-pad-medium); }
.sup-pvm { padding-top: var(--sup-pad-medium); padding-bottom: var(--sup-pad-medium); }

/* Padding Large */
.sup-pal { padding: var(--sup-pad-large); }
.sup-prl { padding-right: var(--sup-pad-large); }
.sup-pll { padding-left: var(--sup-pad-large); }
.sup-ptl { padding-top: var(--sup-pad-large); }
.sup-pbl { padding-bottom: var(--sup-pad-large); }
.sup-phl { padding-left: var(--sup-pad-large); padding-right: var(--sup-pad-large); }
.sup-pvl { padding-top: var(--sup-pad-large); padding-bottom: var(--sup-pad-large); }

/* Padding Extra Large */
.sup-paxl { padding: var(--sup-pad-xlarge); }
.sup-prxl { padding-right: var(--sup-pad-xlarge); }
.sup-plxl { padding-left: var(--sup-pad-xlarge); }
.sup-ptxl { padding-top: var(--sup-pad-xlarge); }
.sup-pbxl { padding-bottom: var(--sup-pad-xlarge); }
.sup-phxl { padding-left: var(--sup-pad-xlarge); padding-right: var(--sup-pad-xlarge); }
.sup-pvxl { padding-top: var(--sup-pad-xlarge); padding-bottom: var(--sup-pad-xlarge); }

/* Padding None */
.sup-pan { padding: 0; }
.sup-prn { padding-right: 0; }
.sup-pln { padding-left: 0; }
.sup-ptn { padding-top: 0; }
.sup-pbn { padding-bottom: 0; }
.sup-phn { padding-left: 0; padding-right: 0; }
.sup-pvn { padding-top: 0; padding-bottom: 0; }

/* Margin
------------------------------------------------------------------------------ */

/* Margin Small */
.sup-mas { margin: var(--sup-pad-small); }
.sup-mrs { margin-right: var(--sup-pad-small); }
.sup-mls { margin-left: var(--sup-pad-small); }
.sup-mts { margin-top: var(--sup-pad-small); }
.sup-mbs { margin-bottom: var(--sup-pad-small); }
.sup-mhs { margin-left: var(--sup-pad-small); margin-right: var(--sup-pad-small); }
.sup-mvs { margin-top: var(--sup-pad-small); margin-bottom: var(--sup-pad-small); }

/* Margin Medium */
.sup-mam { margin: var(--sup-pad-medium); }
.sup-mrm { margin-right: var(--sup-pad-medium); }
.sup-mlm { margin-left: var(--sup-pad-medium); }
.sup-mtm { margin-top: var(--sup-pad-medium); }
.sup-mbm { margin-bottom: var(--sup-pad-medium); }
.sup-mhm { margin-left: var(--sup-pad-medium); margin-right: var(--sup-pad-medium); }
.sup-mvm { margin-top: var(--sup-pad-medium); margin-bottom: var(--sup-pad-medium); }
.sup-mal { margin: var(--sup-pad-medium); }

/* Margin Large */
.sup-mrl { margin-right: var(--sup-pad-large); }
.sup-mll { margin-left: var(--sup-pad-large); }
.sup-mtl { margin-top: var(--sup-pad-large); }
.sup-mbl { margin-bottom: var(--sup-pad-large); }
.sup-mhl { margin-left: var(--sup-pad-large); margin-right: var(--sup-pad-large); }
.sup-mvl { margin-top: var(--sup-pad-large); margin-bottom: var(--sup-pad-large); }

/* Margin x-Large */
.sup-mrxl { margin-right: var(--sup-pad-xlarge); }
.sup-mlxl { margin-left: var(--sup-pad-xlarge); }
.sup-mtxl { margin-top: var(--sup-pad-xlarge); }
.sup-mbxl { margin-bottom: var(--sup-pad-xlarge); }
.sup-mhxl { margin-left: var(--sup-pad-xlarge); margin-right: var(--sup-pad-xlarge); }
.sup-mvxl { margin-top: var(--sup-pad-xlarge); margin-bottom: var(--sup-pad-xlarge); }

/* Margin none */
.sup-man { margin: 0; }
.sup-mrn { margin-right: 0; }
.sup-mln { margin-left: 0; }
.sup-mtn { margin-top: 0; }
.sup-mbn { margin-bottom: 0; }
.sup-mhn { margin-left: 0; margin-right: 0; }
.sup-mvn { margin-top: 0; margin-bottom: 0; }

/* MediaQuery utils
------------------------------------------------------------------------------ */
@media (max-width: 40rem) {
  .sup-flr-when-xs {
    float: right;
  }
  .hide-when-xs {
    display: none;
  }
}
