@import url("imports/vars.css");

.sup-comments-navigation,
.sup-comments-trigger {            /* deprecated selector */
  border-radius: var(--sup-input-fields-border-radius);
  display: inline-block;
  position: relative;
  padding: 7px 52px 15px 26px;
  display: inline-block;
  position: relative;
  background-color: var(--sup-color-superblue);
  text-align: left;
}

.sup-comments-navigation::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 14px solid var(--sup-color-superpale);
  top: 16px;
  position: absolute;
  right: 19px;
}

.sup-comments-navigation:hover::after {
  border-top-color: var(--sup-color-supershine);
}

.sup-comments-navigation__trigger,
.comment-button {                  /* deprecated selector */
  background-color: var(--sup-color-white);
  color: var(--sup-color-superblue);
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  padding: 0px 10px;
  margin: 0;
  position: relative;
}

.sup-comments-navigation__trigger::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 7px solid var(--sup-color-white);
  border-bottom: 10px solid transparent;
}


/* deprecated? */
a.sup-comments-trigger { text-decoration: none; }

.sup-commentsmetadata {
  padding-right: 19px;
  color: #999;
}

.sup-comments-date {
  flex-direction: row;
  justify-content: flex-end;
}

.comment-button p.comment-speech-bubble { margin: 0; }

.comment-button-l {
  height: 100px;
  margin: 0;
  padding: 0;
}

.comment-button-transparent { background-color: transparent; }

.comment-speech-bubble-l {

}

.comment-speech-bubble {
  display: inline-block;
  padding: 4px 14px;
  border-radius: 10px;
  position: relative;
  margin: 0;
}

.comment-speech-bubble::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid var(--sup-color-white);
  border-bottom: 10px solid transparent;
}

.comment-speech-bubble-l::after { border-left-color: var(--sup-color-supershade); }

.sup-comments input[type="button"],
.sup-comments input[type="submit"],
.sup-comments button {
  border-radius: var(--sup-input-fields-border-radius);
  background-color: var(--sup-color-superblue);
  color: var(--sup-color-white);
  border: 0;
  padding-left: var(--sup-comments-button-padding);
  padding-right: var(--sup-comments-button-padding);
  width: auto;
}

.sup-comments input[type="button"]:disabled,
.sup-comments input[type="submit"]:disabled,
.sup-comments button:disabled {
  background-color: var(--sup-gray-bergen);
}

.sup-comments input,
.sup-comments textarea {
  font-size: 18px;
  padding: 10px;
  width: 100%;
}

.sup-comments label {
  display: block;
  font-weight: bold;
}

.sup-comments .comment-body {
  position: relative;
  border-radius: var(--sup-input-fields-border-radius);
  padding: var(--sup-pad-xlarge);
}

.comment-body .sup-avatar img { border-radius: var(--sup-comments-small-border-radius); }
.sup-comments-rules-link { height: 100%; }

.sup-comments li {
  list-style-type: none;
  margin: 0;
}

.sup-arrow-d {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 16px solid transparent;
  top: 27px;
  position: absolute;
  right: 38px;
}
