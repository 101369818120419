/* Import fonts
------------------------------------------------------------------------------ */

@font-face {
  font-family: 'SuperLFTEtica';
  font-weight: 300;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Book.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Book.woff2') format('woff2')
}

@font-face {
  font-family: 'SuperLFTEtica';
  font-weight: 600;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-SemiBold.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-SemiBold.woff2') format('woff2')
}

@font-face {
  font-family: 'SuperLFTEtica';
  font-weight: 700;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Bold.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Bold.woff2') format('woff2')
}

/* Deprecated fonts
* Moving over to same font family but with different properties
------------------------------------------------------------------------------ */
@font-face {
  font-family: 'SuperLFTEtica';
  font-weight: 500;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Book.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Book.woff2') format('woff2')
}

@font-face {
  font-family: 'SuperLFTEticaBook';
  font-weight: 500;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Book.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Book.woff2') format('woff2')
}

@font-face {
  font-family: 'SuperLFTEticaSemiBold';
  font-weight: 600;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-SemiBold.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-SemiBold.woff2') format('woff2')
}

@font-face {
  font-family: 'SuperLFTEticaBold';
  font-weight: 700;
  font-style: normal;
  src: url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Bold.woff') format('woff'),
       url('https://static.nrksuper.no/superstil-css/latest/fonts/LFTEticaNRKSuper-Bold.woff2') format('woff2')
}

/* Base
------------------------------------------------------------------------------ */
html * {
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  } /* NOTE: Neeeded to trigger the declared font-face as default font */
h1, h2, h3, h4, h5, h6, blockquote { font-family: 'SuperLFTEtica', sans-serif; font-weight: 600; }

/* Sizes
------------------------------------------------------------------------------ */
.sup-font-xs-small { font-size: 0.75rem; line-height: 1.0rem; }
.sup-font-small { font-size: 1rem; line-height: 1.25rem; }
.sup-font-medium { font-size: 1.5rem; line-height: 1.75rem; }
.sup-font-large { font-size: 1.5rem; line-height: 1.75rem; }

@media (min-width: 52rem) {
  .sup-font-large { font-size: 3.375rem; line-height: 4rem; }
}
