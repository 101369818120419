.sup-byline { margin: 0; }

.sup-byline__image,
.sup-byline__caption {
  display: inline-block;
  vertical-align: middle;
  width: 65px;
  height: auto;
}

.sup-byline__caption {
  margin-left: 5px;
  width: calc(100% - 80px);
  line-height: 1.25;
}
