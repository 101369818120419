.sup-list-inline {
  list-style-type: none;
  padding: 0;
  width: 100%;
  position: relative;
}

.sup-list-inline::before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: var(--sup-color-superchalk);
  bottom: -2px;
  left: 0;
}

.sup-list-inline > * { display: inline-block; }
.sup-list-inline li { position: relative; }

.sup-list-inline__tab {
  border: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 1.25rem;
  margin: 0;
  background: transparent;
  border-bottom: 2px solid transparent;
  bottom: -2px;
  position: relative;
}

.sup-list-inline__tab:hover, .sup-list-inline__tab--active { border-color: var(--sup-color-black); }
