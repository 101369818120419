/* Buttons
------------------------------------------------------------------------------ */
.sup-btn::-moz-focus-inner { border: 0; padding: 0; } /* Reset Firefox */
.sup-btn {
  -webkit-appearance: none; /* Reset iOS */
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  margin: 3px 1px;
  padding: 20px;
  border: 0 solid;          /* Set solid style for potential psuedo inheritance */
  border-radius: .25rem;
  font: 500 1.75rem/1 'SuperLFTEtica', 'Asap', sans-serif;
  color: inherit;
  background: transparent;
  text-decoration: none;
  text-align: center;       /* Make text center in both buttons and links */
  transition: .15s;
}

.sup-btn:active { transform: scale(.98); }

.sup-btn:disabled,
.sup-btn--disabled {
  filter: grayscale(.7);
  pointer-events: none;
  box-shadow: none;
  cursor: default;
  opacity: .6;
}
.sup-btn svg { vertical-align: middle; }  /* Align with text */

.sup-btn--size-small {
  font-size: 1rem;
  padding: 15px;
  font-size: 400;
}

/* deprecated */
.sup-btn--o {
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.sup-btn--o svg {
  display: block;
  width: 100%;
  height: 100%;
}

.sup-btn--o--size-small {
  width: 20px;
  height: 20px;
}


/* Input
------------------------------------------------------------------------------ */
.sup-input {
  border-radius: var(--sup-input-fields-border-radius);
  border: 1px solid var(--sup-gray-london);
  box-sizing: border-box;
}
.sup-input:focus {
  outline: 1px solid var(--sup-gray-bergen);
}

.sup-form-label,
.sup-input-label {
  font-size: 1rem;
  line-height: 1.25rem;
  display: block;
  font-weight: 500;
}

.sup-input-text {
  font-size: 1rem;
  line-height: 1.25rem;
}

.sup-input-text--full { width: 100%; }

.sup-textarea {
  font-size: 1rem;
  line-height: 1.25rem;
}

.sup-textarea--full { width: 100%; }

/* Checkbox
------------------------------------------------------------------------------ */
.sup-checkbox {
  position: absolute; /* take it out of document flow */
  opacity: 0; /* hide it */
}

.sup-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

/* Box. */
.sup-checkbox + label::before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: var(--sup-color-white);
  border-radius: 4px;
  border: 2px solid var(--sup-gray-detroit);
}

/* Box hover and focus */
.sup-checkbox:hover + label::before,
.sup-checkbox:focus + label::before {
  border-color: var(--sup-color-superblue);
  box-shadow: 0 0 4px 1px var(--sup-color-superchalk);
}

.sup-checkbox:checked + label::before {
  border-color: var(--sup-color-superblue);
  background: var(--sup-color-superblue);
}

/* Disabled state label. */
.sup-checkbox:disabled + label {
  border-color: var(--sup-gray-bergen);
  cursor: auto;
}

/* Disabled box. */
.sup-checkbox:disabled + label::before { border-color: var(--sup-gray-bergen); }

/* disable disabled box shadow hover effect */
.sup-checkbox:disabled:hover + label::before { box-shadow: none; }

/* Checkmark. Could be replaced with an image */
.sup-checkbox:checked + label::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: var(--sup-color-white);
  width: 2px;
  height: 2px;
  box-shadow:
    2px 0 0 var(--sup-color-white),
    4px 0 0 var(--sup-color-white),
    4px -2px 0 var(--sup-color-white),
    4px -4px 0 var(--sup-color-white),
    4px -6px 0 var(--sup-color-white),
    4px -8px 0 var(--sup-color-white);
  transform: rotate(45deg);
}

.sup-radio {
  position: absolute; /* take it out of document flow */
  opacity: 0; /* hide it */
}

.sup-radio + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

/* Box. */
.sup-radio + label::before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: var(--sup-color-white);
  border: 2px solid var(--sup-gray-detroit);
  border-radius: 20px;
}

/* Box hover and focus */
.sup-radio:hover + label::before,
.sup-radio:focus + label::before {
  border-color: var(--sup-color-superblue);
  box-shadow: 0 0 4px 1px var(--sup-color-superchalk);
}

.sup-radio:checked + label::before {
  border-color: var(--sup-color-superblue);
  background: var(--sup-color-superblue);
}

/* Disabled state label. */
.sup-radio:disabled + label {
  border-color: var(--sup-gray-bergen);
  cursor: auto;
}

/* Disabled box. */
.sup-radio:disabled + label::before { border-color: var(--sup-gray-bergen); }

/* disable disabled box shadow hover effect */
.sup-radio:disabled:hover + label::before { box-shadow: none; }

/* Checkmark. Could be replaced with an image */
.sup-radio:checked + label::after {
  content: '';
  position: absolute;
  background: var(--sup-color-white);
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 6px;
  left: 6px;
}
