@import url("imports/vars.css");

.sup-wp-article-body p,
.sup-wp-article-body li {
  line-height: 1.625em;
  color: var(--sup-color-black);
  font-size: 100%;
  margin: 0 0 1.375em;
}

.sup-wp-article-body h2,
.sup-wp-article-body h3,
.sup-wp-article-body h4,
.sup-wp-article-body h5,
.sup-wp-article-body h6 {
  color: var(--sup-color-supershade);
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.36;
  margin: 0;
  padding-top: 0.5em;
}

.sup-wp-article-body blockquote {
  quotes: "\201C""\201D""\2018""\2019";
  position: relative;
}

.sup-wp-article-body blockquote:before {
  color: var(--sup-color-superpale);
  content: open-quote;
  position: absolute;
  font-size: 200px;
  left: -74px;
  top: -78px;
}

.sup-wp-article-body blockquote p {
  font-style: italic;
  font-size: 28px;
  line-height: 1.30;
  font-weight: 200;
  margin: 0;
  font-style: normal;
}

.sup-wp-article-body a { color: var(--sup-color-superchalk); }

.sup-wp-article-body {
  padding: 0 10%;
}
