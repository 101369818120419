:root {
  /* Superblue */
  --sup-color-supershine: #d4f8f9;
  --sup-color-superlight: #55e4e8;
  --sup-color-superpale: #00d7dc;
  --sup-color-superchalk: #00b4c3;
  --sup-color-superblue: #00a0b4;
  --sup-color-supershade: #008295;
  --sup-color-superdusk: #00687C;
  --sup-color-superdark: #004e5f;
  --sup-color-supernight: #00323e;


  /* Gray shades */
  --sup-gray-bergen: #ccc;
  --sup-gray-london: #999;
  --sup-gray-detroit: #666;
  --sup-gray-moss: #333;

  /* Other colors */
  --var-color-marine: #004071;
  --sup-color-coral: #ff5a64;
  --sup-color-sunshine: #ffee00;
  --sup-color-black: #0F1417;
  --sup-color-white: #fff;

  /* Utils */
  --sup-container: 1180px;
  --sup-pad-small: 0.1875rem;
  --sup-pad-medium: 0.375rem;
  --sup-pad-large: 0.5625rem;
  --sup-pad-xlarge: 0.75rem;

  /* Forms */
  --sup-input-fields-border-color: #c9c9c9;
  --sup-input-fields-disabled-color: #dadada;
  --sup-input-fields-border-radius: .25rem;
  --sup-comments-button-padding: 60px;
  --sup-comments-small-border-radius: 8px;
}
